/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { ConfigDtoGenericResponse } from '../models/config-dto-generic-response';
import { MenuDtoGenericResponse } from '../models/menu-dto-generic-response';
import { TableSectionDtoListGenericResponse } from '../models/table-section-dto-list-generic-response';

@Injectable({ providedIn: 'root' })
export class SettingsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `v1SettingsGet()` */
  static readonly V1SettingsGetPath = '/v1/settings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1SettingsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1SettingsGet$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ConfigDtoGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, SettingsService.V1SettingsGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConfigDtoGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1SettingsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1SettingsGet$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<ConfigDtoGenericResponse> {
    return this.v1SettingsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConfigDtoGenericResponse>): ConfigDtoGenericResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1SettingsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1SettingsGet$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ConfigDtoGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, SettingsService.V1SettingsGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConfigDtoGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1SettingsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1SettingsGet$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<ConfigDtoGenericResponse> {
    return this.v1SettingsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConfigDtoGenericResponse>): ConfigDtoGenericResponse => r.body)
    );
  }

  /** Path part for operation `v1SettingsSectionsStoreIdGet()` */
  static readonly V1SettingsSectionsStoreIdGetPath = '/v1/settings/sections/{storeId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1SettingsSectionsStoreIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1SettingsSectionsStoreIdGet$Plain$Response(
    params: {
      storeId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<TableSectionDtoListGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, SettingsService.V1SettingsSectionsStoreIdGetPath, 'get');
    if (params) {
      rb.path('storeId', params.storeId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TableSectionDtoListGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1SettingsSectionsStoreIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1SettingsSectionsStoreIdGet$Plain(
    params: {
      storeId: number;
    },
    context?: HttpContext
  ): Observable<TableSectionDtoListGenericResponse> {
    return this.v1SettingsSectionsStoreIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TableSectionDtoListGenericResponse>): TableSectionDtoListGenericResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1SettingsSectionsStoreIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1SettingsSectionsStoreIdGet$Json$Response(
    params: {
      storeId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<TableSectionDtoListGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, SettingsService.V1SettingsSectionsStoreIdGetPath, 'get');
    if (params) {
      rb.path('storeId', params.storeId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TableSectionDtoListGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1SettingsSectionsStoreIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1SettingsSectionsStoreIdGet$Json(
    params: {
      storeId: number;
    },
    context?: HttpContext
  ): Observable<TableSectionDtoListGenericResponse> {
    return this.v1SettingsSectionsStoreIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<TableSectionDtoListGenericResponse>): TableSectionDtoListGenericResponse => r.body)
    );
  }

  /** Path part for operation `v1SettingsGetMenuGet()` */
  static readonly V1SettingsGetMenuGetPath = '/v1/settings/get-menu';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1SettingsGetMenuGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1SettingsGetMenuGet$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MenuDtoGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, SettingsService.V1SettingsGetMenuGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MenuDtoGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1SettingsGetMenuGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1SettingsGetMenuGet$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<MenuDtoGenericResponse> {
    return this.v1SettingsGetMenuGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MenuDtoGenericResponse>): MenuDtoGenericResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `v1SettingsGetMenuGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1SettingsGetMenuGet$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MenuDtoGenericResponse>> {
    const rb = new RequestBuilder(this.rootUrl, SettingsService.V1SettingsGetMenuGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MenuDtoGenericResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `v1SettingsGetMenuGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  v1SettingsGetMenuGet$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<MenuDtoGenericResponse> {
    return this.v1SettingsGetMenuGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<MenuDtoGenericResponse>): MenuDtoGenericResponse => r.body)
    );
  }

}
