import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConfigRepository } from '../repositories/config.repository';

export const modeGuard = (): Observable<boolean | UrlTree> => {
  const configState = inject(ConfigRepository);
  const router = inject(Router);

  return configState.isBasketMode$.pipe(
    map((isBasketMode: boolean) => {
      if (!isBasketMode) {
        return router.parseUrl('/');
      }

      return true;
    })
  )
}
