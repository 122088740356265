import { inject, Injectable } from '@angular/core';

import { IS_BROWSER } from '../tokens/browser';
import { LOCAL_STORAGE } from '../tokens/local-storage';

@Injectable({
  providedIn: 'root',
})
export class LocalstorageService {
  private isBrowser: boolean = inject(IS_BROWSER);
  private localStorage: Storage = inject(LOCAL_STORAGE);

  get(key: string): any {
    if (!this.isBrowser) {
      return;
    }
    const res = this.localStorage.getItem(key);
    if (res) {
      return JSON.parse(res);
    }
    return undefined;
  }

  set(key: string, data: any): void {
    if (!this.isBrowser) {
      return;
    }
    this.localStorage.setItem(key, JSON.stringify(data));
  }

  remove(key: string): void {
    if (!this.isBrowser) {
      return;
    }
    this.localStorage.removeItem(key);
  }
}
