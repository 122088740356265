import { InjectionToken } from '@angular/core';
import { LangOption } from './lang-option.interface';

export const LANG_OPTIONS = new InjectionToken<{[key: string]: LangOption}>('Lang keys', {
  providedIn: 'root',
  factory() {
    return {
      'ru': {name: 'Russian', translateName: 'Русский'},
      'en': {name: 'English', translateName: 'English'},
      'de': {name: 'German', translateName: 'Deutsch'},
      'zh': {name: 'Chinese', translateName: '中文'},
      'ar': {name: 'Arabic', translateName: 'العربية'},
      'az': {name: 'Azerbaijani', translateName: 'azərbaycan dili'},
      'bg': {name: 'Bulgarian', translateName: 'български'},
      'cs': {name: 'Czech', translateName: 'čeština'},
      'es': {name: 'Spanish', translateName: 'español'},
      'fr': {name: 'French', translateName: 'français'},
      'it': {name: 'Italian', translateName: 'italiano'},
      'ka': {name: 'Georgian', translateName: 'ქართული'},
      'lt': {name: 'Lithuanian', translateName: 'lietuvių'},
      'lv': {name: 'Latvian', translateName: 'latviešu'},
      'mn': {name: 'Mongolian', translateName: 'монгол'},
      'nl': {name: 'Dutch', translateName: 'Nederlands'},
      'pl': {name: 'Polish', translateName: 'polski'},
      'ro': {name: 'Romanian', translateName: 'română'},
      'sr': {name: 'Serbian', translateName: 'српски'},
      'th': {name: 'Thai', translateName: 'ไทย'},
      'tr': {name: 'Turkish', translateName: 'Türkçe'},
      'uk': {name: 'Ukrainian', translateName: 'українська'},
      'vi': {name: 'Vietnamese', translateName: 'Tiếng Việt'},
    }
  },
});
