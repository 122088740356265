export function SeparateTask() {
  return (target: {}, key: string, descriptor: PropertyDescriptor) => {
    const method = descriptor.value;

    descriptor.value = function (...args: unknown[]) {
      setTimeout(() => method.apply(this, args), 1);
    };

    return descriptor;
  }
}
