import { NgModule } from '@angular/core';
import { Routes } from '@angular/router';
import { brokenGuard } from './shared/guards/broken.guard';
import { modeGuard } from './shared/guards/mode.guard';
import { fireEvent } from './shared/utils/fire-event';

function chunkLoadError() {
  fireEvent('chunkLoadError');
  return Noop;
}

/*
* loadChildren требует любой модуль на выходе. catch ничего не отдает, поэтому падает сборка
* Чтобы обработать ошибку и не получить новую, подсовываем пустой модуль и обрабатываем событие chunkLoadError.
* */
@NgModule()
class Noop {}

export const APP_ROUTES: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/main/main.routes').then(m => m.routes).catch(chunkLoadError)},
  {
    path: 'ordering',
    loadComponent: () => import('./pages/ordering/ordering.component').catch(chunkLoadError),
  },
  {
    path: 'ordering/:orderId',
    loadComponent: () => import('./pages/ordering/ordering.component').catch(chunkLoadError),
    canActivate: [modeGuard],
  },
  {
    path: 'order-status/:orderId',
    loadComponent: () => import('./pages/order-status/order-status.component').catch(chunkLoadError),
    canActivate: [modeGuard],
  },

  {path: 'additional/:slug', loadComponent: () => import('./pages/text-page/text-page.component').catch(chunkLoadError)},
  {path: 'actions', loadChildren: () => import('./pages/actions/actions.routes').then(m => m.routes).catch(chunkLoadError)},

  {path: 'about', loadComponent: () => import('./pages/text-page/text-page.component').catch(chunkLoadError), data: {type: 'about'}},
  {path: 'privacy', loadComponent: () => import('./pages/text-page/text-page.component').catch(chunkLoadError), data: {type: 'privacy_policy'}},

  {path: 'outlets', loadComponent: () => import('./pages/resto-list/resto-list.component').catch(chunkLoadError)},
  {path: 'outlet/:id', loadComponent: () => import('./pages/resto-list/resto-list.component').catch(chunkLoadError)},

  {path: 'customer-area', redirectTo: 'customer-area/profile', pathMatch: 'full'},
  {
    path: 'customer-area',
    loadChildren: () => import('./pages/customer-area/customer-area.routes').then(m => m.routes).catch(chunkLoadError),
  },

  {path: 'page-not-found', loadComponent: () => import('./pages/page-404/not-found.component').catch(chunkLoadError)},
  {
    path: 'broken',
    loadComponent: () => import('./pages/broken/broken.component').catch(chunkLoadError),
    canActivate: [brokenGuard],
  },

  {path: '**', loadComponent: () => import('./pages/page-404/not-found.component').catch(chunkLoadError)},
];
