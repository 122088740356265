/* tslint:disable */
/* eslint-disable */
export enum DeliveryStatus {
  Unconfirmed = 'UNCONFIRMED',
  Waitcooking = 'WAITCOOKING',
  Readyforcooking = 'READYFORCOOKING',
  Cookingstarted = 'COOKINGSTARTED',
  Cookingcompleted = 'COOKINGCOMPLETED',
  Waiting = 'WAITING',
  Onway = 'ONWAY',
  Delivered = 'DELIVERED',
  Closed = 'CLOSED',
  Cancelled = 'CANCELLED'
}
