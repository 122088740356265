import { Inject, Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { catchError, from, Observable, of, tap } from 'rxjs';

import { ConfigDto } from '../api/v1/models/config-dto';
import { VisualTheme } from '../api/v1/models/visual-theme';
import { SettingsService } from '../api/v1/services/settings.service';
import { ConfigRepository } from '../shared/repositories/config.repository';
import { SplashScreenService } from '../shared/services/splash-screen.service';
import { map } from 'rxjs/operators';
import { SwUpdate } from '@angular/service-worker';
import { ConfigDtoGenericResponse, Layout } from '../api/v1/models';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SettingLoadService {
  constructor(
    private configStore: ConfigRepository,
    private settings: SettingsService,
    private router: Router,
    private meta: Meta,
    private splashScreenService: SplashScreenService,
    private swUpdate: SwUpdate,
    @Inject(DOCUMENT) private document: Document,
  ) {
    if(typeof navigator !== 'undefined' && navigator.serviceWorker && navigator.serviceWorker.controller) {
      this.swUpdate.checkForUpdate().then(isUpdate => {
        if(!isUpdate){return}
        window?.location.reload();
      })
    }

    this.configStore.layout$.subscribe((layout) => {
      this.layoutTheme = layout.toLowerCase();
      this.loadStyles();
      if (layout === Layout.Fresh) {
        this.defaultFont = 'Montserrat';
      } else {
        this.defaultFont = 'Roboto';
      }
    });
  }

  private defaultFont: string;

  private layoutTheme: string;

  private loadStyles(): void {
    const head = this.document.getElementsByTagName('head')[0];
    const themeLink = this.document.getElementById(
      'client-theme'
    ) as HTMLLinkElement;

    if (themeLink) {
      themeLink.href = this.layoutTheme + '.css';
    } else {
      const style = this.document.createElement('link');
      style.rel = 'stylesheet';
      style.href = `${this.layoutTheme + '.css'}`;

      head.appendChild(style);
    }
  }

  private getSettingObservable(): Observable<ConfigDtoGenericResponse> {
    if(typeof Worker !== 'undefined' && typeof window !== 'undefined'){
      return from((window as any)['settingFetchPromise']) as Observable<ConfigDtoGenericResponse>;
    }
    return this.settings.v1SettingsGet$Json()
  }

  getSettings(): Observable<ConfigDto | null> {
    this.configStore.setLoadingState(true);

    return this.getSettingObservable().pipe(
      map((response => {
        if(response.error) {
          throw new Error('settings is not found');
        }

        return response.result;
      })),
      tap((settings: ConfigDto | null) => {
        this.configStore.setLoadingState(false);

        if(!settings) {return}
        this.configStore.setConfig(settings);

        if (settings.theme) {
          settings.theme.fontFamily = settings.theme.fontFamily ?? this.defaultFont;
          this.setPageMetaContent(settings.theme);
        }
      }),
      tap(() => this.splashScreenService.showContent()),
      catchError(() => {
        this.configStore.setLoadingState(false);
        this.configStore.setConfig(null);
        /*
        * В момент работы APP_INITIALIZER маршрутизация Router еще не заинициализирована. Поэтому не сработает переход.
        * */
        setTimeout(() => {
          void this.router.navigateByUrl('/broken');
          this.splashScreenService.showContent();
        })
        return of(null);
      }),
    )
  }

  private setPageMetaContent(theme: VisualTheme): void {
    this.meta.addTag({
      name: 'theme-color',
      content: theme.backgroundColor,
    });
  }
}
