import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

import { Outlet } from '../api/v1/models/outlet';
import { OutletOpenHours } from '../shared/interfaces/outlet-open-hours';
import { SeoData } from '../api/v1/models/seo-data';
import { ConfigRepository } from '../shared/repositories/config.repository';
import { IS_BROWSER } from '../shared/tokens/browser';
import { LOCATION } from '../shared/tokens/location';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  route: string;
  currentRoute: string;
  seoData: SeoData;
  activeOutlet: Outlet;
  workingHours: OutletOpenHours;
  sharingImg: string;

  constructor(
    private title: Title,
    private meta: Meta,
    private configStore: ConfigRepository,
    @Inject(LOCATION) private location: Location,
    @Inject(DOCUMENT) private document: Document,
    @Inject(IS_BROWSER) private isBrowser: boolean,
  ) {
    this.configStore.seoData$.subscribe((seoData: SeoData | null) => {
      if (seoData) {
        this.seoData = seoData;
      }
    });

    this.configStore.activeOutlet$.subscribe((outletData: Outlet | undefined) => {
      if (outletData) {
        this.activeOutlet = outletData;
      }
    });

    this.configStore.workingHours$.subscribe((workingData: OutletOpenHours | null) => {
      if (workingData) {
        this.workingHours = workingData;
      }
    });

    this.configStore.sharingImageUrls$.subscribe((sharingImg: string | undefined) => {
      if (sharingImg) {this.sharingImg = sharingImg;}
    });
  }

  setMetaTags(event: any): void {
    this.currentRoute = event.url;
    if (this.currentRoute.match(/product/g)) {
      return;
    } else if (this.currentRoute === '/' || this.currentRoute.includes('/category/')) {
      this.setMainPageMetaTags();
    } else if (this.currentRoute === '/about') {
      this.setTextPageMetaTags();
    } else {
      this.setDefaultMetaTags();
    }
  }

  setDefaultMetaTags(): void {
    this.updateTitle();
    this.setDefaultDescription();
    this.addCanonicalTag(this.location.href);
  }

  setMainPageMetaTags(): void {
    this.addCanonicalTag(this.location.href);
    if (this.seoData) {
      this.updateTitle($localize `:@@Menu:Menu`);
      this.updateDescription(this.seoData.pageMetaDescription);
    } else {
      this.setDefaultTitle();
      this.setDefaultDescription();
    }
  }

  setTextPageMetaTags(): void {
    this.addCanonicalTag(this.location.href);
    if (this.activeOutlet) {
      this.updateTitle(this.activeOutlet?.name);
      this.meta.updateTag({ property: 'og:title', content: this.activeOutlet?.name });

      if (this.workingHours) {
        this.updateDescription(
          this.activeOutlet?.address + ' - ' +
          $localize `:@@WorkingHoursFrom:Working from` + ' ' + this.workingHours?.open + ' ' +
          $localize `:@@To:to` + ' ' + this.workingHours?.close
        );
      } else {
        this.updateDescription(this.activeOutlet?.address);
      }
    } else {
      this.setDefaultTitle();
      this.setDefaultDescription();
    }
  }

  setDynamicDataMetaTags(property: string, description: string | undefined): void {
    this.addCanonicalTag(this.location.href);
    this.updateTitle(property);

    if (description) {
      this.updateDescription(description);
    } else {
      this.setDefaultDescription();
    }
  }

  setDefaultTitle(): void {
    if (this.seoData && this.seoData.pageTitle) {
      this.title.setTitle(this.seoData.pageTitle);
      this.meta.updateTag({ property: 'og:title', content: this.seoData.pageTitle });
      this.meta.updateTag({ property: 'og:site_name', content: this.seoData.pageTitle });
    } else {
      const defaultTitle = $localize`:@@MetaTagsTitle:Food delivery`;
      this.title.setTitle(defaultTitle);
      this.meta.updateTag({ property: 'og:title', content: defaultTitle });
      this.meta.updateTag({ property: 'og:site_name', content: defaultTitle });
    }
  }

  setDefaultDescription(): void {
    const defaultDescription = $localize`:@@MetaTagsDescription:Order food from restaurants — with fast delivery. You can place an order on the website. Pay by cash or credit card.`;
    this.meta.updateTag({ property: 'description', content: defaultDescription });
    this.meta.updateTag({ property: 'og:description', content: defaultDescription });
  }

  updateTitle(title?: string | null): void {
    if (title && this.seoData) {
      this.title.setTitle(this.seoData.pageTitle + ' - ' + title);
      this.meta.updateTag({ property: 'og:title', content: title });
    } else {
      this.setDefaultTitle();
    }
  }

  updateDescription(description?: string): void {
    if (!description || description === '') {
      this.setDefaultDescription();
    } else {
      this.meta.updateTag({ property: 'description', content: description });
      this.meta.updateTag({ property: 'og:description', content: description });
    }
  }

  get pageTitle(): string {
    return this.title.getTitle();
  }

  addCanonicalTag(link: string): void {
    if (!this.isBrowser) {
      return;
    }
    const canonicalTag = this.document.querySelector('[rel="canonical"]');
    if (this.document.head.contains(canonicalTag) && canonicalTag) {
      canonicalTag.setAttribute('href', link)
    } else {
      const head = this.document.getElementsByTagName('head')[0];
      const tag = this.document.createElement('link');
      tag.setAttribute('rel', 'canonical')
      tag.setAttribute('href', link)
      head.appendChild(tag);
    }
  }

}
