/* tslint:disable */
/* eslint-disable */
export enum OrderStatus {
  New = 'NEW',
  Creationinitiated = 'CREATIONINITIATED',
  Trncreationfailed = 'TRNCREATIONFAILED',
  Creationsuccess = 'CREATIONSUCCESS',
  Rmscreationfailed = 'RMSCREATIONFAILED',
  Cancelled = 'CANCELLED',
  Closed = 'CLOSED',
  Abandoned = 'ABANDONED'
}
