import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConfigRepository } from '../repositories/config.repository';

export const brokenGuard = (): Observable<boolean | UrlTree> => {
  const configStore = inject(ConfigRepository);
  const router = inject(Router);

  return configStore.isHasSettings$.pipe(
    map((isHasSettings: boolean) => {
      if (isHasSettings) {
        return router.parseUrl('/');
      }

      return true;
    }),
  );
}
