import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SplashScreenService {

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) {}

  showContent(): void {
    const splashScreen = this.document.getElementById('splash-screen');

    if (splashScreen) {
      splashScreen.remove();
    }
  }

  showImg(url: string): void {
    const image = this.document.createElement('img');
    const imageParent = this.document.getElementById('splash-img');
    image.src = url || './assets/images/default-logo.png';
    image.setAttribute('alt', 'logo');
    image.setAttribute('height', '100%');
    image.setAttribute('width', '100%');

    if (imageParent) {
      imageParent.appendChild(image);
    }
  }
}
