export function convertTZ(date: string | Date, tzString: string | undefined): Date {
  //Локаль технический смысл имеет
  return new Date((typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', {timeZone: tzString}));
}

export function createDate(date: Date, isUTC: boolean): Date {
  if (isUTC) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes()));
  }
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes());
}
