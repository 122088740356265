/* tslint:disable */
/* eslint-disable */
export enum OutletMode {
  None = 'NONE',
  Qr = 'QR',
  Pickup = 'PICKUP',
  Delivery = 'DELIVERY',
  PickupDelivery = 'PICKUP_DELIVERY',
  Working = 'WORKING',
  Eatin = 'EATIN'
}
