import { inject, InjectionToken } from '@angular/core';
import { WINDOW } from './window';

export const GEOLOCATION = new InjectionToken<Geolocation | null>('geolocation object', {
  providedIn: 'root',
  factory: () => {
    const navigator = inject(WINDOW).navigator;
    if (navigator && "geolocation" in navigator) {
      return navigator.geolocation;
    }

    return null
  }
})
