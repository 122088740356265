import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { StatusMessage } from '../interfaces/message-status.interface';

@Injectable({
  providedIn: 'root'
})
export class StatusMessageService {
  constructor() {}

  private _dialogSubj$: Subject<'accept' | 'deny' | string> = new Subject<'accept' | 'deny' | string>();
  get dialogSubj$(): Observable<'accept' | 'deny' | string> {
    return this._dialogSubj$.asObservable()
  }

  private _statusMessage: Subject<StatusMessage> = new Subject<StatusMessage>();
  get statusMessage$(): Observable<StatusMessage> {
    return this._statusMessage.asObservable();
  }

  setStatusMessage(status: StatusMessage): void {
    this._statusMessage.next(status);
  }

  setDialogState(key: 'accept' | 'deny' | string){
    this._dialogSubj$.next(key);
  }
}
