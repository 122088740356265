import { inject, InjectionToken } from '@angular/core';
import { WINDOW } from './window';

export const IS_MOBILE = new InjectionToken<boolean>('is mobile', {
  providedIn: 'root',
  factory() {
    const window = inject(WINDOW);
    const isHasTouch = 'ontouchstart' in window || window.navigator.maxTouchPoints > 0;
    return isHasTouch || /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);
  }
})
