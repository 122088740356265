import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BackLinkService {

  private linkList: Set<string> = new Set<string>();
  private backHistory: Set<string> = new Set<string>();

  get backLink(): string {
    let lastValue = this.getLastLink();

    while(lastValue && this.backHistory.has(lastValue)){
      this.linkList.delete(lastValue);
      lastValue = this.getLastLink();
    }

    if(lastValue){
      this.backHistory.add(lastValue);
      return lastValue;
    }

    return '/';
  }

  stackLink(link: string){
    if(this.linkList.has(link)){
      this.linkList.delete(link)
    }
    this.linkList.add(link);
  }

  private getLastLink(): string | undefined {
    let lastValue;

    //Find last item in Set
    for(lastValue of this.linkList);

    return lastValue;
  }
}
