import { isPlatformBrowser } from '@angular/common';
import { inject, InjectionToken, PLATFORM_ID } from '@angular/core';

export const IS_BROWSER = new InjectionToken<boolean>('Is browser?', {
  factory() {
    const platform = inject(PLATFORM_ID);

    return isPlatformBrowser(platform);
  },
});
