import { ErrorHandler, Injectable } from '@angular/core';

import { StatusMessageService } from './status-message.service';

const errorTranscript: {[key: string] : string} = {
  'Cost rule for order could not be found': $localize`:@@ResponseErrorCostRulesNotFound:The delivery conditions are not set up. Contact the restaurant.`,
  'CUSTOMER_IS_DELETED': $localize`:@@ResponseErrorCustomerIsDeleted:Oops... Unfortunately, we were unable to provide the user with such data. If you have previously registered, please write about it in the comments to the order, and we will fix everything.`
}

@Injectable({
  providedIn: 'root'
})
export class ErrorService implements ErrorHandler {
  constructor(private statusMessage: StatusMessageService) { }

  handleError(event: any): void {
    if(event.error && event.error.message) {
      this.statusMessage.setStatusMessage({
        title: $localize`:@@MessageModalTitleError:Error`,
        message: errorTranscript[event.error.message] ? errorTranscript[event.error.message] : event.error.message,
        type: 'error'
      });
    }
    else {
      this.statusMessage.setStatusMessage({
        title: $localize`:@@MessageModalTitleError:Error`,
        message: $localize`:@@ResponseErrorSomethingElse:Something went wrong...`,
        type: 'error'
      });
    }
  }
}
