import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import * as Sentry from '@sentry/angular-ivy';
import { Observable } from 'rxjs';

import { ApiConfiguration } from './api/v1/api-configuration';
import { ConfigDto } from './api/v1/models/config-dto';
import { APP_ROUTES } from './app.routes';
import { ErrorInterceptor } from './services/error.interceptor';
import { SettingLoadService } from './services/setting-load.service';

function loadSettings(settings: SettingLoadService): () => Observable<ConfigDto | null> {
  return () => settings.getSettings();
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(APP_ROUTES, withComponentInputBinding()),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable'
    }),
    importProvidersFrom(BrowserModule),
    {
      provide: ApiConfiguration,
      useValue: {rootUrl: '/api'}
    },
    {
      provide: APP_INITIALIZER,
      useFactory: loadSettings,
      deps: [SettingLoadService],
      multi: true
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
  ]
}
